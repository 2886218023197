import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <section className="section secondary">
            <div className="container-large">
                <div className="footer-flex-container-top">
                    <div className="footer-column">
                        <a href="/" className="logo w-nav-brand w--current">
                            <div className="heading regular">SCREENPLAYER</div>
                        </a>
                    </div>
                    <div className="footer-column right">
                        <a href="https://x.com/screendj" className="footer-link">X (Twitter)</a>
                        <a href="https://www.instagram.com/screenplayer.co.uk" className="footer-link">Instagram</a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Footer; 