import React from 'react';
import './Header.css';
import { APP_URL } from '../../constants';

function Header() {
    return (
        <section className="section overflow-hidden">
            <div className="container-large">
                <div className="header-layout">
                    <div className="header-content-bottom">
                        <div className="header-description-wrapper">
                            <div className="header-description">
                                <h3 className="heading large">
                            Hollywood-style analysis for short scripts
                        </h3>
                                <div className="spacer-24"></div>
                                <p className="paragraph large">
                                • Professional script coverage with grades on story’s strengths <br />• Logline, summary and treatment <br />• Scene-by-scene breakdown<br />• Character lists, locations, costumes, makeup, SFX and VFX breakdowns 
<br />• Props and set dressing  breakdown <br />• Budget estimation<br />• Things to consider in production
                                </p>
                                <div className="spacer-24"></div>
                                <a href={APP_URL} className="button w-button">TRY IT NOW →</a>
                            </div>
                        </div>
                        <div className="hero-image-wrapper">
                            <video 
                                src="/screenplayer_video.MP4" 
                                className="hero-video" 
                                autoPlay 
                                loop 
                                muted 
                                playsInline 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Header; 