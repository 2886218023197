import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Login from './components/Login/Login/Login';
import Content from './forms/Content';
import NavBar from './components/Landing2/NavBar';

const App = () => {
  const { isAuthenticated, logout, user, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {!isAuthenticated ? (
        <div>
          <NavBar />
          <Login />
        </div>
      ) : (
        <Content handleLogout={() => logout({ returnTo: window.location.origin })} user={user} />
      )}
    </div>
  );
};

export default App;
