import React from 'react';
import './Card.css';

function Card({ imgSrc, title, description }) {
    return (
        <div className="card-item">
            <img src={imgSrc} alt={title} className="icon-large" />
            <div className="spacer-64"></div>
            <h3 className="heading large">{title}</h3>
            <div className="spacer-16"></div>
            <p className="paragraph">{description}</p>
            <div className="corner-cuts-wrapper text-color-accent">
                <div className="corner-cut-top-left w-embed">
                    <svg width="100%" height="100%" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0H72L0 72V0Z" fill="currentColor" />
                    </svg>
                </div>
                <div className="corner-cut-top-right w-embed">
                    <svg width="100%" height="100%" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M72 3.49691e-06V72L0 0L72 3.49691e-06Z" fill="currentColor" />
                    </svg>
                </div>
                <div className="corner-cut-bottom-right w-embed">
                    <svg width="100%" height="100%" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M72 72V0L0 72H72Z" fill="currentColor" />
                    </svg>
                </div>
                <div className="corner-cut-bottom-left w-embed">
                    <svg width="100%" height="100%" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 72H72L0 0V72Z" fill="currentColor" />
                    </svg>
                </div>
            </div>
        </div>
    );
}

export default Card; 