import React from 'react';
import './NavBar.css';
import TypingHeader from './TypingHeader/TypingHeader';
import { FRONT_URL, APP_URL } from '../../constants';

function NavBar() {
    return (
        <div className="nav w-nav " role="banner">
            <div className="nav-container">
                <div className="logo-wrapper">
                    <a href={FRONT_URL} className="logo w-nav-brand w--current">
                    <div className="header-left">
                        <header>
                            <TypingHeader text="Screenplayer" />
                        </header>
                    </div>
                    </a>
                </div>
                <div className="actions-wrapper">
                    <a href={APP_URL} className="button-secondary small hide-mobile-portrait w-button">Try IT NOW →</a>
                </div>
            </div>
            <div className="nav-gradient-underline"></div>
        </div>
    );
}

export default NavBar; 