import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react'; // Import useAuth0
import Details from './Details'; // Import the Details component
import { API_URL } from '../constants/index';
import './Screenplay.css';

const Screenplay = ({ selectedItem }) => {
  const [data, setData] = useState(null);
  const [progress, setProgress] = useState(100);
  const { getAccessTokenSilently } = useAuth0(); // Use the useAuth0 hook

  useEffect(() => {
    if (selectedItem !== "" && data && !data.is_processed) {
      const progress_bar = async () => {
        try {
          const token = await getAccessTokenSilently();
          const res = await fetch(`${API_URL}/screenplay_processing_progress/${selectedItem}`, {
            headers: {
              Authorization: `Bearer ${token}` // Use the token from useAuth0
            }
          });
          const data = await res.json();
          const progress = data.progress;
          setProgress(prevState => {
            // Clear interval
            if (progress >= 100) {
              clearInterval(interval);
            }
            return progress; // Update the state
          });
        } catch (error) {
          console.log('Something went wrong...', error);
          clearInterval(interval);
        }
      };

      progress_bar();
      let interval = setInterval(progress_bar, 5000);
      return () => clearInterval(interval); // Clear interval when the component unmounts
    }
  }, [selectedItem, data, getAccessTokenSilently]);

  useEffect(() => {
    const fetchScreenplay = async () => {
      if (selectedItem !== "") {
        try {
          const token = await getAccessTokenSilently();
          const res = await fetch(`${API_URL}/screenplay/${selectedItem}`, {
            headers: {
              Authorization: `Bearer ${token}` // Use the token from useAuth0
            }
          });
          if (!res.ok) {
            throw new Error(res.status);
          }
          const data = await res.json();
          setData(data);
        } catch (error) {
          console.error('Error:', error);
        }
      }
    };

    fetchScreenplay();
  }, [selectedItem, getAccessTokenSilently]); // Include getAccessTokenSilently as a dependency

  if (data) {
    return (
      <div className="screenplay-container">
        <div className="screenplay">
          <div className="screenplay-header">
            <div className="screenplay-info">
              <h3>{data.original_name}</h3>
              <p>Uploaded at: {new Date(data.uploaded_at).toDateString()}; id: {data.id}</p>
              {(data.is_processed || progress === 100) ? <></> : (<p>Processing In Progress... {progress}/100%</p>)}
            </div>

            {(data.is_processed || progress === 100) ? (<>{''}</>) : (<div className="loading-wheel"></div>)}

          </div>
          {<Details screenplayId={selectedItem} progress={progress} />}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Screenplay;
