import React from 'react';
import NavBar from './NavBar';
import Header from './Header';
import About from './About';
import Footer from './Footer';
import './Landing2.css';

function Landing2() {
    return (
        <div>
            <NavBar />
            <Header />
            <About />
            <Footer />
        </div>
    );
}

export default Landing2; 